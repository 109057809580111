@font-face {
  font-family: 'PingFangSC-Regular';
  src:
    local('PingFangSC-Regular'),
    url(./assets/PingFangSC-Regular.woff) format('woff'),
    url(./assets/PingFangSC-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'PingFangSC-Semibold';
  src:
    local('PingFangSC-Semibold'),
    url(./assets/PingFangSC-Semibold.woff) format('woff'),
    url(./assets/PingFangSC-Semibold.ttf) format('truetype');
}
@font-face {
  font-family: 'PingFangSC-Medium';
  src:
    local('PingFangSC-Medium'),
    url(./assets/PingFangSC-Medium.woff) format('woff'),
    url(./assets/PingFangSC-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'AdobeHeitiStd-Regular';
  src:
    local('AdobeHeitiStd-Regular'),
    url(./assets/AdobeHeitiStd-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'AppleHeiti-Medium';
  src:
    local('AppleHeiti-Medium'),
    url(https://wagonsclub.oss-cn-beijing.aliyuncs.com/static/fonts/AppleHeiti-Medium.ttf)
      format('truetype');
}

@font-face {
  font-family: 'ArialMT';
  src:
    local('ArialMT'),
    url(./assets/ArialMT.woff) format('woff'),
    url(./assets/ArialMT.ttf) format('truetype');
}
@font-face {
  font-family: 'Impact';
  src:
    local('Impact'),
    url(./assets/Impact.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  /* 抄自简书 */
  font-family: 'lucida grande', 'lucida sans unicode', lucida, helvetica, 'Hiragino Sans GB',
    'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
  overflow-x: hidden;
  background: #f5f5f5;
}

body.no-scroll {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.safe-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

/* flex layout */

.flex-row {
  display: flex;
  flex-direction: row;
  position: relative;
}

.flex-column {
  display: flex;
  flex-direction: column;
  position: relative;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.toastify-container {
  display: flex !important;
  top: 42vh !important;
  right: 0 !important;
  width: initial !important;
  max-width: 80vw !important;
  flex-direction: column !important;
  margin: 0 auto !important;
}

.toastify-info,
.toastify-error {
  margin: 4px auto !important;
  min-height: unset !important;
  border-radius: 8px !important;
  /* 微信浏览器不支持 #rrggbbaa 这种写法，不过支持 rgba(r, g, b, a) 这样 :-( */
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.toastify-body {
  text-align: center !important;
  color: white !important;
}

.ui.modal {
  border-radius: 0;
}

.ui.dimmer {
  background-color: rgba(255, 255, 255, 0.45);
}

@font-face {
  font-family: '思源黑体-粗';
  font-display: swap;
  src: url('//at.alicdn.com/t/webfont_r7qntq7i8r9.eot'); /* IE9*/
  src:
    url('//at.alicdn.com/t/webfont_r7qntq7i8r9.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('//at.alicdn.com/t/webfont_r7qntq7i8r9.woff2') format('woff2'),
    url('//at.alicdn.com/t/webfont_r7qntq7i8r9.woff') format('woff'),
    /* chrome、firefox */ url('//at.alicdn.com/t/webfont_r7qntq7i8r9.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('//at.alicdn.com/t/webfont_r7qntq7i8r9.svg#思源黑体-粗') format('svg'); /* iOS 4.1- */
}

.wagons-bold {
  font-family: '思源黑体-粗' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

/* 游艇页面 地图圆角 */
.amap-container {
  border-radius: 10px;
}

/* 样式调试边框 */
.red_border {
  border: 1px solid #f00;
}
.green_border {
  border: 1px solid green;
}

/* 自定义卡片样式 */
.my_card {
  background-color: #fff;
  box-shadow:
    1px 1px 1px #e8e8e8,
    -1px 1px 1px #e8e8e8;
}
