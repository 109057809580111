
.tables{
  min-width: '100%';
  display: block;

  border: 1px solid #ddd;
  border-collapse: collapse;
  white-space: none;
  overflow-x: auto; 


}
.tables  thead tr th {
height: 40px;
background-color: rgba(68,120,245,0.05);
border: 1px solid #ddd;
text-align: center;
}
.tables  thead tr th td {
  padding: 8px;
  min-width: 60px;
  background-color: #f7f7f7;
    border: 1px solid #ddd;
    text-align: center;

}
.tables  tbody tr td {
  min-width: 60px;
  padding: 8px;
    border: 1px solid #ddd;
    text-align: center;
}


